import Provider from '@provider'
import { IntegrationModel, IntegrationDetailModel } from '@/models'
import { isObject } from 'lodash';

export default {
  getIntegrations: ({ cancelToken }) => {
    return Provider.api
      .get(`/integration/list`, { cancelToken })
      .then(response => response.data.data?.map?.(data => new IntegrationModel(data)));
  },

  getIntegration: ({ integrationSlug, cancelToken }) => {
    return Provider.api
      .get(`/integration/${integrationSlug}`, { cancelToken })
      .then(response =>
        response.data.data
          ? new IntegrationModel(response.data.data)
          : response.data
      );
  },

  createIntegration: ({
    integrationSlug,
    referer,
    key,
    appKey,
    country,
    url,
    username,
    password
  }) => {
    return Provider.api
      .post(`/integration/create/${integrationSlug}`, {
        referer,
        key,
        app_key: appKey,
        country,
        url,
        username,
        password
      })
      .then(response => response.data?.result);
  },

  reauthIntegration: ({ integrationSlug, referer }) => {
    return Provider.api
      .post(`integration/reauth/${integrationSlug}`, {
        referer,
      })
      .then(response => response.data?.result);
  },

  getIntegrationDetail: ({ integrationSlug, cancelToken }) => {
    return Provider.api
      .get(`/integration/detail/${integrationSlug}`, { cancelToken })
      .then(response =>
        isObject(response.data?.data)
          ? new IntegrationDetailModel(response.data.data)
          : null
      );
  },

  updateIntegrationSettings: ({
    integrationSlug,
    companyProjectStatusId,
    defaultUsersToProjectChat,
    defaultProjectUserIds,
    createGeneralProjectChat,
    generalProjectChatUserIds,
    remoteCreate,
    watchCompanyProjectStatusId,
    watchCreateEstimateClientStatusId,
    projectStatusId,
    syncStatusIdService,
    syncStatusIdClient,
    templateId,
    projectNumber,
    autoCreate,
    administration,
    division,
    whenQuoteAcceptSetToCompanyProjectStatusId,
    listForContactCompanyProjectStatusId,


    projectOrganizationIds,
    watchSyncProjectServiceStatusId,
    watchChangeProjectServiceStatusId,
    watchChangeProjectClientStatusId,
    remoteCreateProject,
    watchCreateProjectClientStatusId,
    watchCreateProjectServiceStatusId,

    actionCompanyProjectStatusId,

    autoCreateInTwinfield,
    office,

    articles,
    articlesUrenGroups,
    articlesMaterialGroups,

    oneDriveFolderId,
    folderPath,
    lockChooseFolder,
    createArchiveFolder,

    salesTextComments,
    subsectionChecklist,
    // sharepoint
    siteId,
    folderId,
    syncStartDate,
    archivingEnabled
  }) => {
    return Provider.api
      .post(`/integration/settings/${integrationSlug}`, {
        company_project_status_id: companyProjectStatusId === 0 ? null : companyProjectStatusId,
        default_users_to_project_chat: defaultUsersToProjectChat,
        default_project_user_ids: defaultProjectUserIds,
        create_general_project_chat: createGeneralProjectChat,
        general_project_chat_user_ids: generalProjectChatUserIds,
        remote_create: remoteCreate,
        watch_company_project_status_id: watchCompanyProjectStatusId,
        watch_create_estimate_client_status_id: watchCreateEstimateClientStatusId,
        project_status_id: projectStatusId,
        sync_status_id_service: syncStatusIdService,
        sync_status_id_client: syncStatusIdClient,
        template: templateId,
        project_number: projectNumber,
        auto_create_in_exact: autoCreate,

        project_organization_ids: projectOrganizationIds,
        watch_sync_project_service_status_id: watchSyncProjectServiceStatusId,
        watch_change_project_service_status_id: watchChangeProjectServiceStatusId,
        watch_change_project_client_status_id: watchChangeProjectClientStatusId,
        remote_create_project: remoteCreateProject,
        watch_create_project_client_status_id: watchCreateProjectClientStatusId,
        watch_create_project_service_status_id: watchCreateProjectServiceStatusId,

        action_company_project_status_id: actionCompanyProjectStatusId,
        administration: administration,
        division: division,
        when_quote_accept_set_to_company_project_status_id: whenQuoteAcceptSetToCompanyProjectStatusId === 0
            ? null
            : whenQuoteAcceptSetToCompanyProjectStatusId,
        list_for_contact_company_project_status_id: listForContactCompanyProjectStatusId,

        office,
        auto_create_in_twinfield: autoCreateInTwinfield,

        articles: articles,
        articles_uren_groups: articlesUrenGroups,
        articles_material_groups: articlesMaterialGroups,
        one_drive_folder_id: oneDriveFolderId,
        folder_path: folderPath,
        lock_choose_folder: lockChooseFolder,
        create_archive_folder: createArchiveFolder,

        sales_text_comments: salesTextComments,
        subsection_checklist: subsectionChecklist,

        //sharepoint
        site_id: siteId,
        folder_id: folderId,
        sync_start_date: syncStartDate,
        archiving_enabled: archivingEnabled,
      })
      .then(response => response.data);
  },

  stopIntegration: ({
    integrationSlug,
  }) => {
    return Provider.api
      .post(`/integration/stop/${integrationSlug}`)
      .then(response => response.data?.result);
  },

  startIntegration: ({
    integrationSlug,
  }) => {
    return Provider.api
      .post(`/integration/start/${integrationSlug}`)
      .then(response => response.data?.result);
  },

  callIntegrationAction: ({ integrationSlug, action }, payload) => {
    return Provider.api
      .post(`/integration/action/${integrationSlug}/${action}`, payload)
  },

  getOneDriveFolders: ({ folderId }) => {
    return Provider.api
      .get(`/integration/onedrive/choose-folder`, { params: { folderId } })
      .then(({ data }) => data);
  },

  getSharepointSites: () => {
    return Provider.api.get(`/integration/share-point/sites`).then(({ data }) => data);
  },

  getSharepointFolders: ({ siteId }) => {
    return Provider.api
      .get(`/integration/share-point/sites/${siteId}/folders`)
      .then(({ data }) => data);
  },

  startSharepointSync: () => {
    return Provider.api.post(`/integration/share-point/sync/start`).then(({ data }) => data);
  }
};
