import { isNil } from 'lodash';
import Model from './Model';

class IntegrationDetailSettingModel {
  constructor(data) {
    this.companyProjectStatusId = Number(data.company_project_status_id);
    this.defaultProjectUserIds = data.default_project_user_ids;
    this.defaultUsersToProjectChat = data.default_users_to_project_chat;
    this.createGeneralProjectChat = data.create_general_project_chat;
    this.generalProjectChatUserIds = data.general_project_chat_user_ids;

    this.remoteCreate = data.remote_create;
    this.templateId = data.template;
    this.projectNumber = data.project_number;
    this.autoCreate = data.auto_create_in_exact;
    this.watchCompanyProjectStatusId = data.watch_company_project_status_id;
    this.watchCreateEstimateClientStatusId = data.watch_create_estimate_client_status_id;

    this.projectStatusId = data.project_status_id;
    this.syncStatusIdService = data.sync_status_id_service;
    this.syncStatusIdClient = data.sync_status_id_client;

    /* Bouw7 properties */
    this.projectOrganizationIds = isNil(data.project_organization_ids)
      ? null
      : Number(data.project_organization_ids);
    this.watchSyncProjectServiceStatusId = data.watch_sync_project_service_status_id;
    this.watchChangeProjectServiceStatusId = data.watch_change_project_service_status_id;
    this.watchChangeProjectClientStatusId = data.watch_change_project_client_status_id;

    this.remoteCreateProject = data.remote_create_project;
    this.watchCreateProjectClientStatusId = data.watch_create_project_client_status_id;
    this.watchCreateProjectServiceStatusId = data.watch_create_project_service_status_id;
    /* END: Bouw7 properties */

    // Snelstart
    this.actionCompanyProjectStatusId = data.action_company_project_status_id;

    //Kingsoftware
    this.administration = data.administration;

    //Twinfield
    this.autoCreateInTwinfield = data.auto_create_in_twinfield;
    this.office = data.office;

    //ExactCost
    this.whenQuoteAcceptSetToCompanyProjectStatusId =
      Number(data.when_quote_accept_set_to_company_project_status_id);
    this.listForContactCompanyProjectStatusId = data.list_for_contact_company_project_status_id;
    this.articles = Boolean(data.articles);
    this.articlesUrenGroups = Array.isArray(data.articles_uren_groups)
      ? data.articles_uren_groups
      : [];
    this.articlesMaterialGroups = Array.isArray(data.articles_material_groups)
      ? data.articles_material_groups
      : [];
    this.division = data.division;

    //OneDrive
    this.oneDriveFolderId = data.one_drive_folder_id;
    this.folderPath = data.folder_path;
    this.lockChooseFolder = data.lock_choose_folder;
    this.createArchiveFolder = data.create_archive_folder;

    // Proteus
    this.salesTextComments = data.sales_text_comments;
    this.subsectionChecklist = data.subsection_checklist;

    // Sharepoint
    this.siteId = data.site_id;
    this.folderId = data.folder_id;
    this.syncStartDate = data.sync_start_date
      ? Model.prototype.toMomentDate(data.sync_start_date, 'YYYY-MM-DD')
      : null;
    this.archivingEnabled = data.archiving_enabled;
    this.syncStartedAt = data.sync_started_at ? new Date(data.sync_started_at) : null;
    // this.driveId = data.drive_id;
  }
}

export default IntegrationDetailSettingModel;
